<template>
  <LayoutColumns :columns="2" style="margin-bottom: var(--spacer); margin-top: var(--spacer)">
    <template v-for="serie in series">
      <LayoutCard class="device-avg">
        <div class="device-avg-header">
          <p>
            Moyenne
            {{ getRecurrence(store.map.uni.get(serie.unit)!, true) }}
          </p>
          <span class="uptitle">{{ removeRecurrence(serie.name!) }}</span>
        </div>
        <h3>
          {{ Math.round(d2(avg(serie.data as any))) }}
          {{ removeRecurrence(getUnitLabel(store.map.uni.get(serie.unit)!)) }}
        </h3>
      </LayoutCard>
      <LayoutCard class="device-avg" v-if="!skip_total.includes(serie.code)">
        <div class="device-avg-header">
          <p>
            Total
            {{ getRecurrence(store.map.uni.get(serie.unit)!) }}
          </p>
          <span class="uptitle">{{ removeRecurrence(serie.name!) }}</span>
        </div>
        <h3>
          {{ Math.round(d2(sum(serie.data as any))) }}
          {{ removeRecurrence(getUnitLabel(store.map.uni.get(serie.unit)!)) }}
        </h3>
      </LayoutCard>
      <div v-else></div>
    </template>
  </LayoutColumns>
</template>

<script setup lang="ts">
import { d2 } from '@/service/service_calculations'
import LayoutCard from '../layout/LayoutCard.vue'
import LayoutColumns from '../layout/LayoutColumns.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import type { Serie } from '@/stores/store_chart'
import type { components } from '@/types/hypervision_schema'

defineProps<{
  series: Serie[]
}>()

const store = useHypervisionStore()
const skip_total = ['HR', 'POS', 'HC', 'DH', 'OCC', 'DC', 'POURC']
const has_daily_recurrence = ['m3J', 'kWhJ', '€J']
const has_annual_recurrence = ['m3A', 'kWhA', '€A']

function sum(measures: { y: number }[]) {
  let sum = 0
  for (const measure of measures) {
    sum += measure.y ?? 0
  }

  return sum
}

function avg(measures: { y: number }[]) {
  if (measures.length == 0) return 0
  const s = sum(measures)
  return s / measures.length
}

function getUnitLabel(unit: components['schemas']['Unit']) {
  switch (unit.code) {
    case 'M2':
      return 'm²'
    case 'MC':
      return 'm³'
    case 'm3J':
      return 'm³/j'
    case 'm3A':
      return 'm³/an'
    default:
      return unit.symbol
  }
}

function getRecurrence(unit: components['schemas']['Unit'], average: boolean = false) {
  if (has_daily_recurrence.includes(unit.code)) {
    if (average) return 'par jour sur la période selectionnée'
    return 'sur la période sélectionnée'
  } else if (has_annual_recurrence.includes(unit.code)) {
    return 'par an'
  } else if (unit.code === 'MS') {
    return 'par seconde'
  } else {
    return
  }
}

function removeRecurrence(string: String) {
  return string.replace('/j', '').replace('/an', '')
}
</script>

<style scoped>
.device-avg-header > p {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 600;
}

h3 > span {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
</style>
